<template>
  <div>
    <!-- 顶部导航 -->
    <Header></Header>
    <router-view />
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
export default {
  name: "LayOut",
  components: { Header, Footer },
  data() {
    return {
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
