import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/",
        component: () => import("@/views/index/index"),
        meta: { title: "首页", activeMenu: '/' },
      },
      {
        path: "/activity",
        component: () => import("@/views/activity/index"),
        hidden: true,
        meta: { title: "活动页", activeMenu: '/activity' },
      },
      
      {
        path: "/want-tobuy",
        component: () => import("@/views/want-tobuy/index"),
        hidden: true,
        meta: { title: "我要买", activeMenu: '/want-tobuy' },
      },
      {
        path: "/want-tosell",
        component: () => import("@/views/want-tosell/index"),
        hidden: true,
        meta: { title: "我要卖", activeMenu: '/want-tosell' },
      },
      {
        path: "/asset-display",
        component: () => import("@/views/asset-display/index"),
        hidden: true,
        meta: { title: "资产展示", activeMenu: '/asset-display' },
      },
      {
        path: "/want-tobuy/h5-details",
        component: () => import("@/views/want-tobuy/h5-details/index"),
        hidden: true,
        meta: { title: "作品详情", activeMenu: '/want-tobuy' },
      },
	  {
	    path: "/join-us",
	    component: () => import("@/views/join-us/index"),
	    hidden: true,
	    meta: { title: "加入我们", activeMenu: '/join-us' },
	  },
	  {
	    path: "/about-us",
	    component: () => import("@/views/about-us/index"),
	    hidden: true,
	    meta: { title: "联系我们", activeMenu: '/about-us' },
	  }
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.VUE_APP_BASE_URL,
	scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
