// phoneMask.js
export function maskPhoneNumber(phoneNumber) {
  if (typeof phoneNumber !== 'string' || phoneNumber.length <= 4) {
      return phoneNumber;
  }

  const prefix = phoneNumber.slice(0, 3);
  const postfix = phoneNumber.slice(-4);
  const middleLength = phoneNumber.length - prefix.length - postfix.length;
  const maskedMiddle = '*'.repeat(middleLength);

  return prefix + maskedMiddle + postfix;
}
