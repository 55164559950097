<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    this.$nextTick(() => {
			document.getElementById('title').innerHTML = process.env.VUE_APP_TITLE
		})
  }
}
</script>

<style>
#app {
  font-family: 'Noto Sans SC', "Microsoft YaHei", "黑体", "宋体", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 14px;
  line-height: 180%;
  color: #2c3e50;
}
input{
	font-family: 'Noto Sans SC', "Microsoft YaHei", "黑体", "宋体", Helvetica, Arial, sans-serif;
}
/* 全局样式 */
::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
}
 
::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* 设置滚动条背景色 */
}
 
::-webkit-scrollbar-thumb {
  border-radius: 4px; /* 设置滑道圆角 */
  background-color: #ccc; /* 设置滑道颜色 */
}
</style>
