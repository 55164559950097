// import { login, getInfo, logout } from '@/api/login/login'
import { getToken, setToken, removeToken, getStorageKey, clearStorageKey } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    user: {},
    userInfo: getStorageKey("userInfo"),
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    }
  },

  actions: {
    // // 登录
    // Login({ commit }, userInfo) {
    //   const rememberMe = userInfo.rememberMe
    //   return new Promise((resolve, reject) => {
    //     login(userInfo.username, userInfo.password, userInfo.code, userInfo.uuid).then(res => {
    //       setToken(res.token, rememberMe)
    //       commit('SET_TOKEN', res.token)
    //       setUserInfo(res.user, commit)
    //       // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
    //       commit('SET_LOAD_MENUS', true)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // // 获取用户信息
    // GetInfo({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo().then(res => {
    //       setUserInfo(res, commit)
    //       resolve(res)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logOut(commit)
        resolve()
      })
    }
  }
}

export const logOut = (commit) => {
  commit('SET_TOKEN', '')
  removeToken()
	clearStorageKey()
}

export const setUserInfo = (res, commit) => {
  commit('SET_USER', res.user)
}

export default user
