<!-- 头部公共组件 -->
<template>
  <div>
    <div class="mg-head d-flex jc-between ai-center">
      <div class="mg-containMenu d-flex ai-center jc-center">
        <!-- <div class="headLeft"></div> -->
		<img
		  style="width: 60px; height: 60px; margin-right: 20px"
		  src="@/assets/images/logo_bb.png"
		/>
        <h1 class="headline">{{ pageTitle }}</h1>
        <el-tabs
          class="myCustomTab"
          v-model="activeName"
          @tab-click="handleClick"
          :stretch="true"
        >
          <el-tab-pane label="首页" name="/"></el-tab-pane>
          <!-- <el-tab-pane label="资产展示" name="/asset-display"></el-tab-pane> 
          <el-tab-pane label="我要买" name="/want-tobuy"></el-tab-pane>
          <el-tab-pane label="我要卖" name="/want-tosell"></el-tab-pane> -->
          <!-- <el-tab-pane label="活动" name="/activity"></el-tab-pane>
          <el-tab-pane label="关于我们" name="/about-us"></el-tab-pane> -->
		  <el-tab-pane label="加入我们" name="/join-us"></el-tab-pane>
		  <el-tab-pane label="联系我们" name="/about-us"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div class="headRight">
        <div class="d-flex">
          <div class="phonebtn d-flex ai-center jc-center">
            <img
              style="width: 17px; height: 16px; padding-right: 10px"
              src="~@/assets/images/phone.png"
            />
            <span>400-115-9966</span>
          </div>
          <span v-if="!token" class="pl-25" @click="navToAdmin('/user/login')">
            <div class="loginbtn d-flex ai-center jc-center">登录</div>
          </span>
          <span v-if="token" class="pl-25">
            <el-dropdown
              class="avatar-container right-menu-item hover-effect"
              trigger="click"
            >
              <div class="d-flex ai-center text-black-15 fs-14 h-100">
                <i class="custom-icon fs-16"></i>
                <span class="ml-10">{{ infoObj.userName || "-" }}</span>
                <i class="el-icon-arrow-down fs-16 ml-10" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  class="d-flex jc-center ai-center"
                  style="border-bottom: 1px solid #e6e6e6;"
                	@click.native="navToAdmin('/user/user/index')"
                >
                  <i class="custom-icon el-icon--right"></i>
                  个人中心
                </el-dropdown-item>
                <el-dropdown-item
                  @click.native="logout"
                  class="d-flex jc-start ai-center"
                >
                  <i class="logout-icon el-icon--right"></i>退出登录
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { maskPhoneNumber } from "@/utils/phoneMask.js";
import { getToken } from '@/utils/auth'
export default {
  name: "Header",
  computed: {
    // 使用计算属性来调用 maskPhoneNumber电话函数进行脱敏
    maskedPhoneNumber() {
      return maskPhoneNumber(this.phoneNumber);
    },
  },
  data() {
    return {
      activeName: "/",
      phoneNumber: "18207891506",
      pageTitle: process.env.VUE_APP_TITLE,
    };
  },
  created() {
    this.activeName = this.$route.meta.activeMenu
      ? this.$route.meta.activeMenu
      : "/";
  },
	computed: {
		infoObj() {
			return this.showInfo(this.$store.getters.userInfo)
		},
		token(){
			return this.$store.getters.token
		}
	},
  watch: {
    $route(newValue, oldValue) {
      this.activeName = newValue.meta.activeMenu || "/";
    },
  },
  methods: {
		//获取认证状态即用户信息
		showInfo(userInfo){
			let obj = {}
			if(userInfo){
				obj = {
					authStatus: userInfo.authStatus,
					userName: userInfo.phonenumber
				}
			}
			return obj
		},
		//跳转管理端页面方法
    navToAdmin(path) {
			window.open(location.origin + path,"_blank")
    },
    navToLogin(path) {
      location.href = location.origin + path;
    },
    handleClick(tab) {
      this.$router.push(tab.name);
    },
		//退出登录
    logout() {
      this.$confirm("确定退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("LogOut").then(() => {
            location.reload();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.mg-head {
  height: 80px;
  padding: 0 80px;
  background: #FFFFFF;
  .headLeft {
    height: 38px;
    width: 380px;
    background-image: url("@/assets/images/headLogo.png");
    background-repeat: no-repeat;
  }
  .headline {
		color: #55aaff;
		font-size: 30px;
		font-weight: 600;
    min-width: 230px;
		margin: 0;
		transform: skewX(-10deg);
	}
  .phonebtn {
    width: 156px;
    height: 40px;
    cursor: pointer;
    background: #deecff;
    border-radius: 19px;
  }
  .loginbtn {
    width: 108px;
    height: 40px;
    background: linear-gradient(90deg, #0080ff, #4395ff);
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    line-height: 18px;
    font-size: 14px;
  }
  .myCustomTab {
    width: 600px;
    margin: 0 0 0 100px;
    border-bottom: none;
  }
}
@media screen and (max-width: 1280px){
  .mg-head {
    .myCustomTab {
      margin: 0;
    }
  }
}
.custom-icon {
  width: 13px;
  height: 14px;
  background-image: url("@/assets/images/userIcon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.logout-icon {
  width: 14px;
  height: 14px;
  background-image: url("@/assets/images/logoutIcon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;

  &.hover-effect {
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}
::v-deep .el-tabs__nav {
  height: 80px;
  align-items: center;
}
::v-deep .el-tabs__header {
  margin-bottom: 0;
}
::v-deep .is-active {
  width: 63px;
  height: 16px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  font-size: 16px;
  color: #1d76f9 !important;
  line-height: 18px;
}
::v-deep .el-tabs__item {
  height: 16px;
  font-weight: 500;
  font-size: 16px;
  color: #666;
  line-height: 18px;
  width: 20%;
}
::v-deep .el-tabs__active-bar {
  height: 3px;
  width: 60px !important;
  // margin-left: 12px;
  margin-left: 60px;
  background: #1d76f9;
  border-radius: 2px;
}
::v-deep .el-tabs__nav-scroll {
  overflow: visible;
}
::v-deep .el-tabs__nav-wrap {
  overflow: visible;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #f5f7fa;
}
</style>
